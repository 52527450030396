import axios from 'axios'
import { getApiConfig } from '../utils'

export function getApiClient({ endpoint = '', rootUri = '', params = {}, config = {} }) {
  const apiConfig = getApiConfig()
  const defaultConfig = {
    params: {
      // ApiKey: apiConfig.apiKey,
      ...params,
    },
    ...config,
    //   withCredentials: true,
  }

  return axios.get(`${rootUri || apiConfig.apiRootUri}${endpoint}`, defaultConfig).then((response) => {
    if (response?.data?.name === 'AxiosError') {
      throw new Error('Network error')
    }
    return response.data
  })
}

export function postApiClient({ endpoint = '', rootUri = '', payload = {}, config = {}, params = {} }) {
  const apiConfig = getApiConfig()
  const defaultConfig = {
    params: {
      // ApiKey: apiConfig.apiKey,
      ...params,
    },
    ...config,
    //   withCredentials: true,
  }

  return axios
    .post(`${rootUri || apiConfig.apiRootUri}${endpoint}`, payload, defaultConfig)
    .then((response) => {
      if (response?.data?.name === 'AxiosError') {
        throw new Error('Network error')
      }
      return response.data
    })
}

export function putApiClient({ endpoint = '', rootUri = '', payload = {}, config = {}, params = {} }) {
  const apiConfig = getApiConfig()
  const defaultConfig = {
    params: {
      ...params,
    },
    ...config,
    //   withCredentials: true,
  }

  return axios
    .put(`${rootUri || apiConfig.apiRootUri}${endpoint}`, null, defaultConfig)
    .then((response) => {
      if (response?.data?.name === 'AxiosError') {
        throw new Error('Network error')
      }
      return response.data
    })
}

export function deleteApiClient({
  endpoint = '',
  rootUri = '',
  payload = {},
  config = {},
  params = {},
}) {
  const apiConfig = getApiConfig()
  const defaultConfig = {
    params: {
      ...params,
    },
    ...config,
  }

  return axios
    .delete(`${rootUri || apiConfig.apiRootUri}${endpoint}`, defaultConfig)
    .then((response) => {
      if (response?.data?.name === 'AxiosError') {
        throw new Error('Network error')
      }
      return response.data
    })
}
