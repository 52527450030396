import { createTheme, PaletteColorOptions, PaletteOptions } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { colors, constants } from '../tokens'

/* interface IPalette extends Palette {
  tertiary: { main: string }
}

interface ITypography extends Typography {
  allVariants: { color: string, letterSpacing: number }
}
interface ITheme extends Theme {
  palette: IPalette
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette
} */

declare module '@mui/material/styles' {
  /* interface Palette {
    tertiary: {
      main: string
    }
  } */
  // export interface ThemeOptions {}
  export interface PaletteOptions {
    secondary?: PaletteColorOptions
    success?: PaletteColorOptions
  }
}
/* interface Theme {
    
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string
    }
  }*/

const palette: PaletteOptions = {
  secondary: {
    main: colors.globalLightBlue,
    dark: colors.globalLightBlue,
  },
  success: {
    main: colors.success,
  },
}

export const theme = createTheme({
  palette,
  typography: {
    allVariants: {
      color: 'inherit',
      letterSpacing: 0,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1400px !important',
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          maxHeight: '40vh',
          overflowY: 'auto',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: '40px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: colors.globalLink,
          padding: 0,
          paddingRight: '12px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'white',
          '&.Mui-disabled': {
            background: colors.disabled,
          },
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: '#222',
            border: 0,
          },
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          display: 'flex',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // background: colors.transparentWhite,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          background: colors.globalLink,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          zIndex: constants.topLevelZIndex,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: 'auto',
        },
      },
    },
    /*MuiCssBaseline: {
        styleOverrides: `
          input {
            background: white;
          }
        `,
      },*/
  },
})
