import styled from '@emotion/styled'
import { Stack } from '@mui/material'
import { borderRadii, colors, constants } from '../tokens'
import Container from './container'

export default function GlobalFooter({ show = true }) {
  return (
    <Wrapper style={{ display: show ? 'block' : 'none' }}>
      <Container>
        <Stack spacing={2}>
          <SpecialMessage>
            The vaccination policy is often different for each event and venue. Please remember to check
            the event page at{' '}
            <a href="https://www.msg.com" rel="noreferrer" target="_blank">
              {' '}
              MSG Website
            </a>{' '}
            for the event requirements.
          </SpecialMessage>
          <Stack direction={'column'} spacing={2} sx={{ padding: '0 12px' }}>
            <div>
              Copyright &copy; {new Date().getFullYear()} Madison Square Garden. All Rights Reserved
            </div>
            <span>RequesTix Mobile | App Version : 1.6 </span>
            <div>
              <a id="poolCoordinatorItem" data-original-title="" title="">
                Contact
              </a>
            </div>
            <div>
              <a href="http://www.themadisonsquaregardencompany.com/" rel="noreferrer" target="_blank">
                Madison Square Garden Website
              </a>
            </div>
            <div>
              <a href="http://www.msgmarqueeathome.com/" rel="noreferrer" target="_blank">
                Marquee Website
              </a>
            </div>
          </Stack>
        </Stack>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  background-color: ${colors.globalDarkBlue};
  color: white;
  margin-top: ${constants.globalBufferMedium};
  width: 100%;
  color: #cde;
  font-size: 14px;
  padding: ${constants.globalBufferMedium} ${constants.globalBufferSmall};

  a,
  div a {
    color: ${colors.globalLightBlue};
  }
`

const SpecialMessage = styled.div`
  // font-weight: bold;
  font-style: italic;
  padding: ${constants.globalBufferSmall};
  border-radius: ${borderRadii.sm};
  border: 1px solid ${colors.globalMediumBlue};
`
