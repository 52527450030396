import { Container as MUIContainer } from '@mui/material'

export default function Container({
  fullscreen,
  sx,
  children,
  ...other
}: {
  fullscreen?: boolean
  sx?: any
  children?: any
  other?: any
}) {
  let otherStyles = {}
  if (fullscreen) {
    otherStyles = {
      margin: 0,
      padding: '0px !important',
      minWidth: '100%',
    }
  }

  return (
    <MUIContainer sx={{ paddingRight: 0, paddingLeft: 0, ...sx, ...otherStyles }} {...other}>
      {children}
    </MUIContainer>
  )
}
