import React, { Dispatch, useContext, useReducer } from 'react'

const defaultUserPayload = {
  isAuth: false,
  isLoading: false,
  isError: false,
  data: null,
}

const defaultMachineInfo = { data: null }

interface UserPayloadProps {
  isAuth?: boolean
  isLoading?: boolean
  isError?: boolean
  data?: any
}

interface MachineInfoPayloadProps {
  data?: any
}

const defaultPayload = {
  isLoading: false,
  isError: false,
  data: null,
}

interface PayloadProps {
  isLoading?: boolean
  isError?: boolean
  data?: any
  totalCount?: number
}

interface StateProps {
  content: PayloadProps
  currentEvents: PayloadProps
  requestedEvents: PayloadProps
  requestDetail: PayloadProps
  authQueueEvents: PayloadProps
  userInfo: UserPayloadProps
  eventFilters: PayloadProps
  requestFilters: PayloadProps
  authQueueRequestFilters: PayloadProps
  searchEventsData: PayloadProps
  searchEventFilters: PayloadProps
  eventManagementFilters: PayloadProps
  currentEventsManagement: PayloadProps
  poolCoordinatorData: PayloadProps
  poolCoordinatorFilter: PayloadProps
  fulfilmentQueueEvents: PayloadProps
  fulfilmentQueueRequestFilters: PayloadProps
  ticketPools: PayloadProps
  isSearchInitiated: boolean
  orderQueue: PayloadProps
  orderQueueFilters: PayloadProps
  completeQueueRequest: PayloadProps
  completeQueueRequestFilters: PayloadProps
  requestManagement: PayloadProps
  requestManagementFilters: PayloadProps
  machineInfo: MachineInfoPayloadProps
}

interface ActionProps {
  setContent: Function
  setCurrentEvents: Function
  setRequestedEvents: Function
  setRequestDetail: Function
  setAuthQueueEvents: Function
  setUserInfo: Function
  setEventFilters: Function
  setRequestFilters: Function
  setAuthQueueRequestFilters: Function
  setSearchEventsData: Function
  setSearchEventFilters: Function
  setEventManagementFilters: Function
  setCurrentEventsManagement: Function
  setFulfilmentQueueEvents: Function
  setPoolCoordinatorData: Function
  setPoolCoordinatorFilters: Function
  setFulfilmentQueueRequestFilters: Function
  setTicketPools: Function
  setIsSearchInitiated: Function
  setOrderQueue: Function
  setOrderQueueFilters: Function
  setCompleteQueueRequest: Function
  setCompleteQueueRequestFilters: Function
  setRequestManagement: Function
  setRequestManagementFilter: Function
  setMachineInfo: Function
}

export const defaultActions: ActionProps = {
  setContent: (payload = defaultPayload): PayloadProps => payload,
  setCurrentEvents: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setRequestedEvents: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setRequestDetail: (payload = defaultPayload): PayloadProps => payload,
  setAuthQueueEvents: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setUserInfo: (payload = defaultUserPayload): UserPayloadProps => payload,
  setEventFilters: (payload = { data: null }): PayloadProps => payload,
  setRequestFilters: (payload = 0): number => payload,
  setAuthQueueRequestFilters: (payload = { data: null }): PayloadProps => payload,
  setSearchEventsData: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setSearchEventFilters: (payload = { data: null }): PayloadProps => payload,
  setEventManagementFilters: (payload = { data: null }): PayloadProps => payload,
  setCurrentEventsManagement: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setFulfilmentQueueEvents: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setPoolCoordinatorData: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setPoolCoordinatorFilters: (payload = { data: null }): PayloadProps => payload,
  setFulfilmentQueueRequestFilters: (payload = { data: null }): PayloadProps => payload,
  setTicketPools: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setIsSearchInitiated: (payload = { ...defaultPayload }): PayloadProps => payload,
  setOrderQueue: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setOrderQueueFilters: (payload = { data: null }): PayloadProps => payload,
  setCompleteQueueRequest: (payload = { data: null }): PayloadProps => payload,
  setCompleteQueueRequestFilters: (payload = { data: null }): PayloadProps => payload,
  setRequestManagement: (payload = { ...defaultPayload, totalCount: 0 }): PayloadProps => payload,
  setRequestManagementFilter: (payload = { data: null }): PayloadProps => payload,
  setMachineInfo: (payload = { data: null }): MachineInfoPayloadProps => payload,
}

export const defaultState: StateProps = {
  content: {
    isLoading: false,
    isError: false,
    data: null,
  },
  currentEvents: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  requestedEvents: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  requestDetail: {
    isLoading: false,
    isError: false,
    data: null,
  },
  authQueueEvents: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  userInfo: {
    isAuth: false,
    isLoading: false,
    isError: false,
    data: null,
  },
  eventFilters: {
    data: null,
  },
  requestFilters: {
    data: null,
  },
  authQueueRequestFilters: {
    data: null,
  },
  searchEventsData: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 1,
  },
  searchEventFilters: {
    data: null,
  },
  eventManagementFilters: {
    data: null,
  },
  currentEventsManagement: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  poolCoordinatorData: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  poolCoordinatorFilter: {
    data: null,
  },
  fulfilmentQueueEvents: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  fulfilmentQueueRequestFilters: {
    data: null,
  },
  ticketPools: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  isSearchInitiated: false,
  orderQueue: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  orderQueueFilters: {
    data: null,
  },
  completeQueueRequest: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  completeQueueRequestFilters: {
    data: null,
  },
  requestManagement: {
    isLoading: false,
    isError: false,
    data: null,
    totalCount: 0,
  },
  requestManagementFilters: {
    data: null,
  },
  machineInfo: {
    data: null,
  },
}

const actionLabels = {
  SET_CONTENT: 'SET_CONTENT',
  SET_CURRENT_EVENTS: 'SET_CURRENT_EVENTS',
  SET_REQUESTED_EVENTS: 'SET_REQUESTED_EVENTS',
  SET_REQUEST_DETAIL: 'SET_REQUEST_DETAIL',
  SET_AUTH_QUEUE_EVENTS: 'SET_AUTH_QUEUE_EVENTS',
  SET_USER_INFO: 'SET_USER_INFO',
  SET_EVENT_FILTERS: 'SET_EVENT_FILTERS',
  SET_REQUEST_FILTERS: 'SET_REQUEST_FILTERS',
  SET_AUTH_QUEUE_REQUEST_FILTERS: 'SET_AUTH_QUEUE_REQUEST_FILTERS',
  SET_SEARCH_EVENTS_DATA: 'SET_SEARCH_EVENTS_DATA',
  SET_EVENT_MANAGEMENT_FILTERS: 'SET_EVENT_MANAGEMENT_FILTERS',
  SET_CURRENT_EVENTS_MANAGEMENT: 'SET_CURRENT_EVENTS_MANAGEMENT',
  SET_SEARCH_EVENT_FILTERS: 'SET_SEARCH_EVENT_FILTERS',
  SET_FULFILMENT_QUEUE_EVENTS: 'SET_FULFILMENT_QUEUE_EVENTS',
  SET_FULFILMENT_QUEUE_REQUEST_FILTERS: 'SET_FULFILMENT_QUEUE_REQUEST_FILTERS',
  SET_POOL_COORDINATOR_DATA: 'SET_POOL_COORDINATOR_DATA',
  SET_POOL_COORDINATOR_FILTERS: 'SET_POOL_COORDINATOR_FILTERS',
  SET_TICKET_POOLS: 'SET_TICKET_POOLS',
  SET_IS_SEARCH_INITIATED: 'SET_IS_SEARCH_INITIATED',
  SET_ORDER_QUEUE: 'SET_ORDER_QUEUE',
  SET_ORDER_QUEUE_FILTERS: 'SET_ORDER_QUEUE_FILTERS',
  SET_COMPLETE_QUEUE_REQUESTS: 'SET_COMPLETE_QUEUE_REQUESTS',
  SET_COMPLETE_QUEUE_REQUESTS_FILTERS: 'SET_COMPLETE_QUEUE_REQUESTS_FILTERS',
  SET_REQUEST_MANGEMENT_QUEUE: 'SET_REQUEST_MANGEMENT_QUEUE',
  SET_REQUEST_MANGEMENT_FILTERS: 'SET_REQUEST_MANGEMENT_FILTERS',
  SET_MACHINE_INFO: 'SET_MACHINE_INFO',
}

const reducer = (state: StateProps, action: { type: string; payload: any }) => {
  switch (action.type) {
    case actionLabels.SET_CONTENT:
      return {
        ...state,
        content: {
          ...state.content,
          ...action.payload,
        },
      }
    case actionLabels.SET_CURRENT_EVENTS:
      return {
        ...state,
        currentEvents: {
          ...state.currentEvents,
          ...action.payload,
        },
      }
    case actionLabels.SET_REQUESTED_EVENTS:
      return {
        ...state,
        requestedEvents: {
          ...state.requestedEvents,
          ...action.payload,
        },
      }
    case actionLabels.SET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          ...action.payload,
        },
      }
    case actionLabels.SET_REQUEST_DETAIL:
      return {
        ...state,
        requestDetail: {
          ...state.requestDetail,
          ...action.payload,
        },
      }
    case actionLabels.SET_AUTH_QUEUE_EVENTS:
      return {
        ...state,
        authQueueEvents: {
          ...state.authQueueEvents,
          ...action.payload,
        },
      }
    case actionLabels.SET_EVENT_FILTERS:
      return {
        ...state,
        eventFilters: action.payload,
      }
    case actionLabels.SET_REQUEST_FILTERS:
      return {
        ...state,
        requestFilters: action.payload,
      }
    case actionLabels.SET_AUTH_QUEUE_REQUEST_FILTERS:
      return {
        ...state,
        authQueueRequestFilters: action.payload,
      }
    case actionLabels.SET_SEARCH_EVENTS_DATA:
      return {
        ...state,
        searchEventsData: {
          ...state.searchEventsData,
          ...action.payload,
        },
      }
    case actionLabels.SET_SEARCH_EVENT_FILTERS:
      return {
        ...state,
        searchEventFilters: action.payload,
      }
    case actionLabels.SET_EVENT_MANAGEMENT_FILTERS:
      return {
        ...state,
        eventManagementFilters: action.payload,
      }
    case actionLabels.SET_CURRENT_EVENTS_MANAGEMENT:
      return {
        ...state,
        currentEventsManagement: {
          ...state.currentEventsManagement,
          ...action.payload,
        },
      }
    case actionLabels.SET_FULFILMENT_QUEUE_EVENTS:
      return {
        ...state,
        fulfilmentQueueEvents: {
          ...state.fulfilmentQueueEvents,
          ...action.payload,
        },
      }
    case actionLabels.SET_FULFILMENT_QUEUE_EVENTS:
      return {
        ...state,
        fulfilmentQueueEvents: {
          ...state.fulfilmentQueueEvents,
          ...action.payload,
        },
      }
    case actionLabels.SET_FULFILMENT_QUEUE_REQUEST_FILTERS:
      return {
        ...state,
        fulfilmentQueueRequestFilters: action.payload,
      }
    case actionLabels.SET_POOL_COORDINATOR_DATA:
      return {
        ...state,
        poolCoordinatorData: {
          ...state.poolCoordinatorData,
          ...action.payload,
        },
      }
    case actionLabels.SET_POOL_COORDINATOR_FILTERS:
      return {
        ...state,
        poolCoordinatorFilter: action.payload,
      }
    case actionLabels.SET_TICKET_POOLS:
      return {
        ...state,
        ticketPools: {
          ...state.ticketPools,
          ...action.payload,
        },
      }
    case actionLabels.SET_IS_SEARCH_INITIATED:
      return {
        ...state,
        isSearchInitiated: action.payload,
      }
    case actionLabels.SET_ORDER_QUEUE:
      return {
        ...state,
        orderQueue: {
          ...state.orderQueue,
          ...action.payload,
        },
      }
    case actionLabels.SET_ORDER_QUEUE_FILTERS:
      return {
        ...state,
        orderQueueFilters: action.payload,
      }
    case actionLabels.SET_COMPLETE_QUEUE_REQUESTS:
      return {
        ...state,
        completeQueueRequest: {
          ...state.completeQueueRequest,
          ...action.payload,
        },
      }
    case actionLabels.SET_COMPLETE_QUEUE_REQUESTS_FILTERS:
      return {
        ...state,
        completeQueueRequestFilters: action.payload,
      }
    case actionLabels.SET_REQUEST_MANGEMENT_QUEUE:
      return {
        ...state,
        requestManagement: {
          ...state.requestManagement,
          ...action.payload,
        },
      }
    case actionLabels.SET_REQUEST_MANGEMENT_FILTERS:
      return {
        ...state,
        requestManagementFilters: action.payload,
      }

    case actionLabels.SET_MACHINE_INFO:
      return {
        ...state,
        machineInfo: action.payload,
      }
  }
}

type DispatchType = Dispatch<{ type: string; payload: any }>

const getActionWithPayload = (dispatch: DispatchType, type: string) => {
  return (payload: PayloadProps) => dispatch({ type, payload })
}

const getActions = (dispatch: DispatchType) => {
  return {
    setContent: getActionWithPayload(dispatch, actionLabels.SET_CONTENT),
    setCurrentEvents: getActionWithPayload(dispatch, actionLabels.SET_CURRENT_EVENTS),
    setRequestedEvents: getActionWithPayload(dispatch, actionLabels.SET_REQUESTED_EVENTS),
    setRequestDetail: getActionWithPayload(dispatch, actionLabels.SET_REQUEST_DETAIL),
    setAuthQueueEvents: getActionWithPayload(dispatch, actionLabels.SET_AUTH_QUEUE_EVENTS),
    setUserInfo: getActionWithPayload(dispatch, actionLabels.SET_USER_INFO),
    setEventFilters: getActionWithPayload(dispatch, actionLabels.SET_EVENT_FILTERS),
    setRequestFilters: getActionWithPayload(dispatch, actionLabels.SET_REQUEST_FILTERS),
    setAuthQueueRequestFilters: getActionWithPayload(
      dispatch,
      actionLabels.SET_AUTH_QUEUE_REQUEST_FILTERS
    ),
    setSearchEventsData: getActionWithPayload(dispatch, actionLabels.SET_SEARCH_EVENTS_DATA),
    setEventManagementFilters: getActionWithPayload(dispatch, actionLabels.SET_EVENT_MANAGEMENT_FILTERS),
    setSearchEventFilters: getActionWithPayload(dispatch, actionLabels.SET_SEARCH_EVENT_FILTERS),
    setCurrentEventsManagement: getActionWithPayload(
      dispatch,
      actionLabels.SET_CURRENT_EVENTS_MANAGEMENT
    ),
    setFulfilmentQueueEvents: getActionWithPayload(dispatch, actionLabels.SET_FULFILMENT_QUEUE_EVENTS),
    setFulfilmentQueueRequestFilters: getActionWithPayload(
      dispatch,
      actionLabels.SET_FULFILMENT_QUEUE_REQUEST_FILTERS
    ),
    setPoolCoordinatorData: getActionWithPayload(dispatch, actionLabels.SET_POOL_COORDINATOR_DATA),
    setPoolCoordinatorFilters: getActionWithPayload(dispatch, actionLabels.SET_POOL_COORDINATOR_FILTERS),
    setTicketPools: getActionWithPayload(dispatch, actionLabels.SET_TICKET_POOLS),
    setIsSearchInitiated: getActionWithPayload(dispatch, actionLabels.SET_IS_SEARCH_INITIATED),
    setOrderQueue: getActionWithPayload(dispatch, actionLabels.SET_ORDER_QUEUE),
    setOrderQueueFilters: getActionWithPayload(dispatch, actionLabels.SET_ORDER_QUEUE_FILTERS),
    setCompleteQueueRequest: getActionWithPayload(dispatch, actionLabels.SET_COMPLETE_QUEUE_REQUESTS),
    setCompleteQueueRequestFilters: getActionWithPayload(
      dispatch,
      actionLabels.SET_COMPLETE_QUEUE_REQUESTS_FILTERS
    ),
    setRequestManagement: getActionWithPayload(dispatch, actionLabels.SET_REQUEST_MANGEMENT_QUEUE),
    setRequestManagementFilter: getActionWithPayload(
      dispatch,
      actionLabels.SET_REQUEST_MANGEMENT_FILTERS
    ),
    setMachineInfo: getActionWithPayload(dispatch, actionLabels.SET_MACHINE_INFO),
  }
}

export const GlobalStore = React.createContext(defaultState)
export const GlobalActions = React.createContext(defaultActions)

interface StoreProps {
  children: JSX.Element | JSX.Element[]
}

export function GlobalStoreProvider({ children }: StoreProps) {
  const [state = defaultState, dispatch] = useReducer(reducer, defaultState)

  const actions = getActions(dispatch)

  return (
    <GlobalStore.Provider value={state}>
      <GlobalActions.Provider value={actions}>{children}</GlobalActions.Provider>
    </GlobalStore.Provider>
  )
}

export const useGlobalStore = () => {
  const store = useContext(GlobalStore)
  return store
}

export const useGlobalActions = () => {
  const actions = useContext(GlobalActions)
  return actions
}
