import axios from 'axios'

export async function getAppConfig() {
  return axios
    .get(`/api/app-config`, { withCredentials: true })
    .then((response) => {
      if (response?.data?.name === 'AxiosError') {
        throw new Error('Network error')
      }
      return response.data
    })
    .catch((error) => {
      return { error: true, errorMessage: error?.message }
    })
}
