import styled from '@emotion/styled'
import { Button, css } from '@mui/material'
import { MouseEventHandler } from 'react'
import { colors, screenSize } from '../tokens'

interface ComponentProps {
  color?: string
  other?: {}
  children: JSX.Element | JSX.Element[] | string
  fullWidth?: boolean
  variant?: 'contained' | 'text' | 'outlined' | undefined
  mode?: 'light' | 'dark' | undefined
  onClick?: MouseEventHandler
  disabled?: boolean
  styles?: any
  href?: string
}

interface OtherButtonStylesType {
  color: string
  backgroundColor?: string
  width?: string
  height?: string
  opacity: string
}

export default function ButtonPrimary({
  color,
  children,
  variant = 'contained',
  mode = 'dark',
  fullWidth,
  onClick,
  disabled,
  styles,
  href,
}: ComponentProps) {
  const otherButtonStyles: OtherButtonStylesType = {
    color: mode === 'dark' ? '#efefef' : colors.globalDarkBlue,
    opacity: disabled ? '0.75' : '1',
    ...styles,
  }
  if (disabled) {
    otherButtonStyles.backgroundColor = mode === 'light' ? '#ddd' : '#d2d7e0'
    otherButtonStyles.color = '#333'
  }

  if (!disabled) {
    otherButtonStyles.backgroundColor = color
      ? colors[color as keyof typeof colors]
      : colors.globalMediumBlue2
  }

  return (
    <StyledButton
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled}
      variant={variant}
      style={otherButtonStyles}
      sx={otherButtonStyles}
      href={href}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  // background-color: ${colors.globalMediumBlue};
  // background-color: #122d67;
  // background-color: ${colors.globalMediumBlue2};
  height: 45px;
  width: 100%;
  font-size: 16px;

  ${screenSize.large(css`
    max-width: 240px;
    font-size: 13px;
  `)}
`
