import styled from '@emotion/styled'
import { Close } from '@mui/icons-material'
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  ListItem,
  ListItemText,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
  debounce,
} from '@mui/material'

import { useEffect, useMemo, useState } from 'react'
import { useGlobalActions, useGlobalStore } from '../../global-store'
import { getEmployeeById, getEmployees, getUserInfo } from '../api/user'
import { OptionType } from '../common-types'
import { constants } from '../tokens'
import { getCurrentEnv } from '../utils'
import ButtonPrimary from './button-primary'
import FormFieldError from './form-field-error'

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          paddingTop: '4px',
          fontSize: '13px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'white',
          fontSize: '13px',
        },
      },
    },
  },
})

export default function DevUserForm() {
  const { setUserInfo } = useGlobalActions()
  const { userInfo } = useGlobalStore()
  const initialEmail = userInfo.data?.EmployeeDataModel?.Email
  const initialEmpId = userInfo.data?.EmployeeDataModel?.EmployeeId
  const [isQA, setIsQA] = useState(false)
  const [formIsOpen, setFormIsOpen] = useState(false)
  const [userToken, setUserToken] = useState(initialEmail)
  const [searchError, setSearchError] = useState('')
  const [searchIsLoading, setSearchIsLoading] = useState(false)
  const [searchOptions, setSearchOptions] = useState<readonly OptionType[]>([])
  const [searchInput, setSearchInput] = useState(initialEmail)
  const [selectedSearch, setSelectedSearch] = useState({
    label: initialEmail,
    id: '',
  })

  const updateUserInfo = () => {
    let emailToken = userToken
    if (searchInput && searchInput.includes('@')) {
      emailToken = searchInput
    }
    if (emailToken) {
      setUserInfo({ isLoading: true })
      getUserInfo(emailToken)
        .then((data: any) => {
          if (
            !data ||
            !data.EmployeeDataModel ||
            !data.EmployeeDataModel.RequestTixAvaliability ||
            data.EmployeeDataModel.EmployeeStatus.EmployeeStatus === 'I'
          ) {
            setSearchError('There was a problem with the return data')
          } else {
            setUserInfo({
              data: data,
              isLoading: false,
            })
            setUserToken('')
            setSearchInput('')
            setFormIsOpen(false)
          }
        })
        .catch(() => {
          setSearchError('User not identified in the system')
        })
    }
  }

  const cancelChanges = () => {
    // router.reload()
    setFormIsOpen(false)
  }

  const onInputChange = (_: any, newVal: string, reason?: string) => {
    if (reason === 'reset') {
      setSearchInput('')
      setSearchOptions([])
    } else {
      setSearchInput(newVal)
    }
  }

  const onSelectRequestee = (_: any, newOption: OptionType) => {
    setSelectedSearch(newOption)
  }

  const debouncedInputHandler = useMemo(() => debounce(onInputChange, 300), [])

  useEffect(() => {
    const currentEnv = getCurrentEnv()
    setIsQA(
      currentEnv === 'UAT' || currentEnv === 'QA' || currentEnv === 'LOCAL' || currentEnv === 'DEV'
    )
  }, [])

  useEffect(() => {
    if (searchInput && searchInput.length > 2 && searchInput !== initialEmail) {
      setSearchIsLoading(true)
      getEmployees({ searchName: searchInput })
        .then((results) => {
          setSearchIsLoading(false)
          const options = results.map((employee) => ({
            label: employee.FullName || `${employee.FirstName} ${employee.LastName}`,
            id: employee.EmployeeId,
            title: employee.Title,
          }))
          setSearchOptions(options)
        })
        .catch(() => setSearchIsLoading(false))
    }
  }, [searchInput])

  useEffect(() => {
    if (selectedSearch && selectedSearch.id) {
      getEmployeeById({ employeeId: selectedSearch.id }).then((data) => {
        if (data && data.Email) {
          setUserToken(data.Email)
        }
      })
    }
  }, [selectedSearch])

  return !!isQA && formIsOpen ? (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <FormContainer>
          <FormControl id="REQUESTEE_INPUT">
            <Autocomplete
              options={searchOptions}
              filterOptions={(x) => x}
              autoComplete
              freeSolo
              onChange={onSelectRequestee}
              getOptionLabel={(option: OptionType) => option.label || ''}
              value={selectedSearch}
              isOptionEqualToValue={(opt, val) => opt.id === val.id || val.id === ''}
              blurOnSelect={true}
              sx={{ width: '200px', marginRight: '20px' }}
              size="small"
              onInputChange={debouncedInputHandler}
              renderOption={(props, option: { id: string; label: string; title?: string }) => {
                return (
                  <ListItem {...props} key={option.id} disablePadding>
                    <ListItemText primary={option.label} secondary={option.title} />
                  </ListItem>
                )
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  key={1}
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    ...(searchIsLoading
                      ? { endAdornment: <CircularProgress color="inherit" size={20} /> }
                      : {}),
                  }}
                />
              )}
            />
            <FormFieldError error={!!searchError} message="Valid name required" />
          </FormControl>
          <ButtonPrimary
            color="success"
            fullWidth={false}
            styles={{
              minWidth: '50px',
              width: '60px !important',
              height: `${constants.globalBufferLarge} !important`,
              marginRight: `${constants.globalBufferSmall}`,
            }}
            onClick={updateUserInfo}
          >
            GO
          </ButtonPrimary>
          <ButtonPrimary
            styles={{
              minWidth: '50px',
              width: '60px !important',
              height: `${constants.globalBufferLarge} !important`,
            }}
            color="error"
            onClick={cancelChanges}
          >
            <Close />
          </ButtonPrimary>
        </FormContainer>
      </Wrapper>
    </ThemeProvider>
  ) : (
    <Typography variant="caption" onClick={() => setFormIsOpen(true)}>
      {userInfo.data?.EmployeeDataModel?.FirstName} {userInfo.data?.EmployeeDataModel?.LastName}
    </Typography>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
`

const FormContainer = styled(FormControl)`
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  right: ${constants.globalBufferSmall};
  top: ${constants.globalBufferSmall};
`
