/* eslint-disable react-hooks/exhaustive-deps */

import styled from '@emotion/styled'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import { CssBaseline, Grid } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import '@total-typescript/ts-reset'
import { SessionProvider, signIn, useSession } from 'next-auth/react'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { GlobalStoreProvider, useGlobalActions, useGlobalStore } from '../global-store'
import { getAppConfig } from '../src/api/app-config'
import { getContent } from '../src/api/content'
import { getEvents } from '../src/api/events'
import { getMachineInfo } from '../src/api/get-machine-info'
import { getTicketPools } from '../src/api/pool-coordintaor'
import { getAllRequests } from '../src/api/requests'
import { getUserInfo } from '../src/api/user'
import {
  clearStoredInitIndicator,
  getStoredInitIndicator,
  setStoredInitIndicator,
} from '../src/app/auth-signer-queue/util'
import GlobalFooter from '../src/components/global-footer'
import GlobalNav from '../src/components/global-nav'
import { IDLE_TIME } from '../src/config'
import '../src/styles/main.css'
import { theme } from '../src/styles/theme-override'
import { apiKeyStorageKey, apiRootUriStorageKey, setStoredApiConfig } from '../src/utils'

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  const [apiConfigIsValid, setApiConfigIsValid] = useState(false)
  const [appIsOffline, setAppIsOffline] = useState(false)
  const router = useRouter()

  const onIdle = () => {
    if (typeof window !== undefined) {
      router.replace('/session')
    }
  }

  useIdleTimer({ onIdle, timeout: IDLE_TIME })

  useEffect(() => {
    getAppConfig().then((results) => {
      if (results && results.apiRootUri && results.apiKey) {
        setStoredApiConfig({
          [apiRootUriStorageKey]: results.apiRootUri,
          [apiKeyStorageKey]: results.apiKey,
        })

        if (results.appOffline && results.appOffline !== 'false') {
          setAppIsOffline(true)
        }
        setApiConfigIsValid(true)
      }
    })
  }, [])

  useEffect(() => {
    if (appIsOffline) {
      router.replace('/offline')
    }
  }, [appIsOffline])

  // adjust global styles for the admin page
  // there may be a less hacky way to do this with mui theming
  useEffect(() => {
    if (router.pathname.includes('admin')) {
      window.document.body.style.backgroundColor = '#c5c4c0'
      window.document.body.style.backgroundImage = 'none'
    } else {
      window.document.body.style.backgroundImage = `url('/images/main_bg.jpg')`
      window.document.body.style.backgroundColor = `#0f213e`
    }
  }, [router.pathname])

  return apiConfigIsValid ? (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GlobalStoreProvider>
        <div style={{ position: 'relative' }}>
          {router.pathname === '/session' || router.pathname === '/offline' ? (
            <Component {...pageProps} />
          ) : appIsOffline ? (
            <></>
          ) : (
            <SessionProvider session={session}>
              <SessionComponent Component={Component} pageProps={pageProps} />
            </SessionProvider>
          )}
        </div>
      </GlobalStoreProvider>
    </ThemeProvider>
  ) : (
    <></>
  )
}

function SessionComponent({ Component, pageProps }: Omit<AppProps, 'router'>) {
  const { data: session } = useSession()
  const router = useRouter()
  const [publicUrl, setIsPublic] = useState(false)

  useEffect(() => {
    if (session === null || router.pathname.includes('/guest-services')) {
      setIsPublic(true)
      const initIndicator = getStoredInitIndicator()
      if (initIndicator) {
      } else {
        clearStoredInitIndicator()
      }
    }
    if (session === null && !publicUrl) {
      setIsPublic(false)
      const initIndicator = getStoredInitIndicator()
      if (initIndicator) {
        router.replace('/session')
      } else {
        setStoredInitIndicator()
        signIn('azure-ad')
      }
    } else {
      clearStoredInitIndicator()
    }
  }, [session, publicUrl])

  return session || publicUrl ? (
    <DataComponent Component={Component} pageProps={pageProps} session={session} isPublic={publicUrl} />
  ) : (
    <></>
  )
}

interface DataProps extends Omit<AppProps, 'router'> {
  session: any
  isPublic: boolean
}

function DataComponent({ Component, pageProps, session, isPublic }: DataProps) {
  const [isAuth, setIsAuth] = useState<boolean>(false)

  const { currentEvents, requestedEvents, content, userInfo } = useGlobalStore()

  const {
    setCurrentEvents,
    setRequestedEvents,
    setContent,
    setUserInfo,
    setTicketPools,
    setMachineInfo,
  } = useGlobalActions()

  const router = useRouter()

  useEffect(() => {
    if (!userInfo.data && !userInfo.isLoading && session?.user?.email && !isPublic) {
      setUserInfo({ isLoading: true })
      getUserInfo(session.user.email)
        .then((data: any) => {
          if (
            !data ||
            !data.EmployeeDataModel ||
            !data.EmployeeDataModel.RequestTixAvaliability ||
            data.EmployeeDataModel.EmployeeStatus === 'I'
          ) {
            setUserInfo({
              isAuth: true,
              data: [],
              isLoading: false,
            })
            router.replace('/unauth')
          } else {
            setIsAuth(true)
            setUserInfo({
              isAuth: true,
              data: data,
              isLoading: false,
            })
          }
        })
        .catch(() => {
          setUserInfo({ isError: true, data: {}, isLoading: false, isAuth: isAuth })
        })
    } else if (
      isPublic &&
      session === null &&
      !userInfo.data &&
      !userInfo.isLoading &&
      !session?.user?.email
    ) {
      setIsAuth(true)
      setUserInfo({
        isAuth: true,
        data: [],
        isLoading: false,
      })
    }
  }, [userInfo, session])

  useEffect(() => {
    if (userInfo?.isAuth && !content.data && !content.isLoading && !isPublic) {
      setContent({ isLoading: true })
      getContent()
        .then((data) => {
          setContent({
            data: data?.length ? data : [],
            isLoading: false,
          })
        })
        .catch(() => {
          setContent({ isError: true, data: [], isLoading: false })
        })
    }
  }, [userInfo?.isAuth])

  // to reduce the heavy lifting by consuming components, maybe refactor to have api functions handle all error and loading states
  useEffect(() => {
    if (userInfo?.isAuth && !currentEvents?.data && !isPublic) {
      setCurrentEvents({ isLoading: true })
      getEvents()
        .then((data) => {
          setCurrentEvents({
            data: data?.EventsByRequest,
            isLoading: false,
            totalCount: data?.TotalCount,
          })
        })
        .catch(() => {
          setCurrentEvents({ isError: true, data: [], isLoading: false, totalCount: 0 })
        })
    }
  }, [userInfo?.isAuth, currentEvents?.data])

  useEffect(() => {
    if (
      userInfo?.isAuth &&
      !requestedEvents.data &&
      !requestedEvents.isError &&
      !requestedEvents.isLoading &&
      !userInfo.isLoading &&
      userInfo.data &&
      !isPublic
    ) {
      setRequestedEvents({ isLoading: true })
      getAllRequests({ employeeId: userInfo?.data?.EmployeeDataModel?.EmployeeId })
        .then((data) => {
          setRequestedEvents({
            data: data?.SubmittedRequests,
            isLoading: false,
            totalCount: data?.TotalCount,
          })
        })
        .catch(() => {
          setRequestedEvents({ isError: true, data: [], isLoading: false, totalCount: 0 })
        })
    }
  }, [userInfo, requestedEvents, userInfo?.isAuth])

  useEffect(() => {
    getTicketPools()
      .then((data) => {
        setTicketPools({
          data: data && data?.length ? data : [],
          isLoading: false,
          isError: false,
        })
      })

      .catch(() => {
        setTicketPools({ isError: true, data: [], isLoading: false })
      })
  }, [])

  useEffect(() => {
    getMachineInfo()
      .then((data) => {
        setMachineInfo({ data: data?.MachineInfo })
      })
      .catch(() => {
        setMachineInfo({ data: null })
      })
  }, [])

  return (
    <GlobalWrapper>
      <GlobalNav show={!router.query.fullscreen && !router.pathname.includes('guest-services')} />
      <Grid
        container
        sx={{
          margin: '0 auto',
          flexGrow: 1,
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Component {...pageProps} />
      </Grid>
      <GlobalFooter show={!router.query.fullscreen} />
    </GlobalWrapper>
  )
}

const GlobalWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`
