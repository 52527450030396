import React from 'react'
import { PricingDataProps } from './common-types'
import dayjs from 'dayjs'
export const getRandomHash = (): string => Math.random().toString(36).substring(2, 10)

export const arrayHasPartialMatch = (arr: string | string[] | [], str = '') => {
  return Array.isArray(arr) && !!arr.length && arr.some((val: string[]) => val?.includes(str))
}

export const arrayHasPartialMatchReversed = (arr: string[], str = '') => {
  return Array.isArray(arr) && !!arr.length && arr.some((val) => str.includes(val))
}

export const getNumbersFromString = (str: string): string => {
  if (!!str && typeof str === 'string' && str.length) {
    return str.replace(/\s*/g, '').match(/(\d+)/g)?.join('') || ''
  }
  return ''
}

export const getPricingSorted = (currentEventData: any) => {
  const pricingList = currentEventData?.PricePointLinks?.filter(
    (item: PricingDataProps) => !!item.PricePointDetail
  )
    ?.sort(
      (a: PricingDataProps, b: PricingDataProps) =>
        Number(a?.PricePointDetail?.Price) - Number(b?.PricePointDetail?.Price)
    )
    ?.map((item: PricingDataProps) =>
      item.PricePointDetail
        ? {
            IsSoldOut: item.IsSoldOut,
            location: `${item.PricePointDetail?.SeatingLocation}`,
            price: `${toDollarFormat(item?.PricePointDetail?.Price)}`,
            id: item.Id,
          }
        : null
    )
  return pricingList
}

// shamelessly stolen from stackoverflow
export const formatPhoneNumber = (phoneNumberString = ''): string => {
  const cleaned = ('' + phoneNumberString).replace(/\D|^[0-1]/g, '')
  if (cleaned?.length === 10) {
    let phArr = cleaned.split('')
    return `(${phArr.splice(0, 3).join('')}) ${phArr.splice(0, 3).join('')}-${phArr.splice(0).join('')}`
  }

  return cleaned
}

export const formatCcExpDate = (dateString = ''): string => {
  const cleaned = ('' + dateString).replace(/\D/g, '')
  const match = cleaned.match(/(0[1-9]|10|11|12)(20[0-9]{2})$/)
  if (match) {
    return `${match[1]}/${match[2]}`
  }
  return cleaned
}

export const getIsSportsEvent = (eventName = ''): boolean => {
  return arrayHasPartialMatchReversed(['rangers', 'knicks', 'hockey'], eventName?.toLowerCase())
}

export const getIsKnicksRangersEvent = (eventName = ''): boolean => {
  return arrayHasPartialMatchReversed(['rangers', 'knicks'], eventName?.toLowerCase())
}

export const creditCardRegex = [
  { regex: /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/, type: 'VI' },
  { regex: /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$/, type: 'MC' },
  { regex: /^3[4,7]\d{13}$/, type: 'AM' },
  { regex: /^6011-?\d{4}-?\d{4}-?\d{4}$/, type: 'DS' },
]

export const creditCardTypeMap = {
  masterCard: 'MC',
  americanExpress: 'AM',
  discover: 'DS',
  visa: 'VI',
}

export const getCreditCardType = (ccNumber = ''): string => {
  let ccType = ''
  creditCardRegex.forEach((card) => {
    if (card.regex.test(ccNumber)) {
      ccType = card.type
    }
  })
  return ccType
}

export const toDollarFormat = (num = 0): string => {
  if (isNaN(num)) {
    return ''
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(num)
}

// casting cc number return as string to avoid floating point formatting
export const onChangeCcNumber = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  onChange: Function
): string => {
  let newVal = event.target.value?.replace(/[^0-9.]/g, '')
  onChange(newVal + '')
  return newVal
}

// masks input with formatting, eg, (000) 000-0000
export const onChangeCcExpDate = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  onChange: Function
): string => {
  let newVal = formatCcExpDate(event.target.value)
  onChange(newVal)
  return newVal
}

export const onChangeNumberField = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  onChange: Function
) => {
  let newVal = event.target.value?.replace(/[^0-9.]/g, '')

  onChange(newVal === '' ? newVal : Number(newVal))
}

export const onClickNumberField = (event: React.MouseEvent<HTMLDivElement>) => {
  return (event.target as HTMLInputElement)?.select()
}

export const apiRootUriStorageKey = 'API_ROOT_URI'
export const apiKeyStorageKey = 'API_KEY'

export const getApiConfig = () => {
  let apiRootUri = ''
  let apiKey = ''
  if (typeof window !== undefined) {
    apiRootUri = window.localStorage.getItem(apiRootUriStorageKey) || ''
    apiKey = window.localStorage.getItem(apiKeyStorageKey) || ''
  }
  return { apiRootUri, apiKey }
}

export const setStoredApiConfig = (apiConfig = {}) => {
  if (typeof window !== undefined) {
    window.localStorage.setItem(
      apiRootUriStorageKey,
      apiConfig[apiRootUriStorageKey as keyof typeof apiConfig]
    )
    window.localStorage.setItem(apiKeyStorageKey, apiConfig[apiKeyStorageKey as keyof typeof apiConfig])
  }
}

// gleefully stolen from https://www.geeksforgeeks.org/how-to-detect-the-user-browser-safari-chrome-ie-firefox-and-opera-using-javascript/
export const getBrowserDetectionMap = () => {
  try {
    // Get the user-agent string
    let userAgentString = navigator.userAgent

    // Detect Chrome
    let chromeAgent = userAgentString.indexOf('Chrome') > -1

    // Detect Internet Explorer
    let IExplorerAgent = userAgentString.indexOf('MSIE') > -1 || userAgentString.indexOf('rv:') > -1

    // Detect Firefox
    let firefoxAgent = userAgentString.indexOf('Firefox') > -1

    // Detect Safari
    let safariAgent = userAgentString.indexOf('Safari') > -1

    // Discard Safari since it also matches Chrome
    if (chromeAgent && safariAgent) safariAgent = false

    // Detect Opera
    let operaAgent = userAgentString.indexOf('OP') > -1

    // Discard Chrome since it also matches Opera
    if (chromeAgent && operaAgent) chromeAgent = false

    return {
      chrome: chromeAgent,
      firefox: firefoxAgent,
      safari: safariAgent,
      internetexplorer: IExplorerAgent,
      opera: operaAgent,
    }
  } catch (e) {
    return {
      chrome: true,
    }
  }
}

// 'borrowed' from https://stackoverflow.com/questions/1885557/simplest-code-for-array-intersection-in-javascript
export const getArrayIntersection = (a: any[], b: any[]) => {
  const setA = new Set(a)
  const setB = new Set(b)
  const intersection = new Set([...setA].filter((x) => setB.has(x)))
  return Array.from(intersection)
}

export const hostMap = [
  ['LOCAL', 'localhost'],
  ['DEV', '-dev'],
  ['QA', '-qa'],
  ['UAT', '-uat'],
  ['PROD', 'requestix.msg.com'],
  ['PROD', 'requestix-mobile.msg.com'],
]

export const getCurrentEnv = (): string => {
  let currentEnv = ''
  if (window !== undefined && typeof window !== 'undefined') {
    hostMap.forEach((item) => {
      if (window.location?.host?.includes(item[1])) {
        currentEnv = item[0]
      }
    })
  }
  return currentEnv
}

export const emailIsValid = (email?: string) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (!!email && email?.match(emailRegex)) {
    return true
  }
  return false
}

export const phoneIsValid = (phone?: string | number) => {
  // const phoneRegex = /^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  if (!!phone && `${phone}`?.match(phoneRegex)) {
    return true
  }
  return false
}

export const getMasterTrackerExportCSVHeaders = (pricePointDetailId: string | string[] | undefined) => [
  { label: 'RequesTix ID', key: 'RequestNumber' },
  { label: 'Requested on behalf', key: 'RequestedFor' },
  { label: 'Title', key: 'RequestedForTitle' },
  { label: 'Recipient', key: 'Recipient' },
  { label: 'Cell', key: 'MobileNumber' },
  { label: 'Recipient email', key: 'RecipientEmail' },
  ...(pricePointDetailId !== 'Comp/IC' ? [{ label: 'Payment Status', key: 'PaymentStatus' }] : []),
  { label: 'Price point', key: 'SeatPreference' },
  { label: 'Total tix', key: 'TotalTickets' },
  { label: 'Section', key: 'SeatSection' },
  { label: 'Row', key: 'SeatRow' },
  { label: 'Seats', key: 'Seat' },
  { label: 'Archtics ID', key: 'ArchticId' },
  { label: 'Sent', key: 'SentRemarks' },
  { label: 'Purpose text', key: 'Purpose' },
]

export const formatDate = (dateString: string) => {
  if (!dateString) return null
  return dayjs(dateString.replace(' ', 'T') + '.000Z').format('MM/DD/YYYY, h:mm:ss A')
}

export const formatDateWithDayAndMonth = (dateString?: string | null | undefined) => {
  if (!dateString) return null
  return dayjs(dateString.replace(' ', 'T') + '.000Z').format('ddd, MMM DD, YYYY / h:mm a')
}
