import axios from 'axios'
import { endpoints } from '../config'
import { getApiClient } from './api-client'
import mockUserInfo from './mockdata/mockdata-user.json'

interface EmployeeType {
  EmployeeId: string
  ADUserId?: string
  ADName?: string
  FirstName: string
  LastName: string
  FullName: string
  Title?: string
  Department: {
    DepartmentName?: string
    Division: {
      DivisionCode?: string
    }
  }
  DepartmentId?: boolean
  Location?: string
  Phone?: string
  Email?: string
  RequestTixAvaliability?: boolean
  ModifiedBy?: string
  ModifiedDate: '0001-01-01T00:00:00'
  CreatedBy?: string
  CreatedDate: '0001-01-01T00:00:00'
  EmployeeStatus?: string
  CompanyCode?: string
  IsSeniorExecutive?: boolean
  IsVIPUser?: boolean
  IsAlwaysAllowComps?: boolean
  FullPartTime?: boolean
  RequestedForPCTeam?: string[]
}

type GetEmployeeType = EmployeeType[]

export async function getMockUserInfo() {
  return mockUserInfo
}

/* export async function getUserInfo({ userEmail = '' } = {}) {
  const params = {
    email: userEmail,
  }

  return getApiClient({ endpoint: endpoints.userInfo, params })
} */

export async function getUserInfo(userToken: string) {
  const params = {
    email: userToken,
  }
  console.log(
    process.env.NEXT_PUBLIC_API_ROOT_URI,
    `${process.env.NEXT_PUBLIC_API_ROOT_URI}/users/users-info`,
    userToken
  )
  return axios
    .get(`${process.env.NEXT_PUBLIC_API_ROOT_URI}/users/users-info`, { params })
    .then((data) => data?.data)
}

export async function getUserAllocation({ requesteeId = '', seasonDate = '' } = {}) {
  const params = {
    requestedForId: requesteeId,
    seasonDate,
  }

  return getApiClient({ endpoint: endpoints.userAllocation, params })
}

export async function getEmployees({ searchName = '' }): Promise<GetEmployeeType> {
  const params = {
    searchName,
  }

  return getApiClient({ endpoint: endpoints.getEmployees, params })
}

export async function getEmployeeById({ employeeId = '' }): Promise<EmployeeType> {
  const params = {
    employeeId,
  }

  return getApiClient({ endpoint: endpoints.getEmployeeById, params })
}
