import { css } from '@emotion/react'

export const colors = {
  globalDarkBlue: '#0f213e',
  globalMediumBlue: '#1d4c96',
  globalMediumBlue2: '#145593',
  globalMediumBlueTransparent: 'rgba(18, 45, 103, .9)',
  // globalLightBlue: '#99ddff',
  globalLightBlue: '#90caf9',
  globalLighterBlue: '#b6dff3',
  globalLightestBlue: '#e8eeff',
  primaryAction: '#7944da',
  globalDarkestBlue: '#061729',
  globalDarkBlueTransparent: 'rgba(15, 33, 62, .85)',
  transparentWhite: 'rgba(255, 255, 255, .92)',
  transparentWhiteLight: 'rgba(255, 255, 255, .25)',
  globalFontDark: '#44444f',
  globalLink: '#0063bb',
  error: '#d32f2f',
  success: '#048071',
  disabled: '#d2d7e0',
  warning: '#ED6C02',
}

export const borderRadii = {
  sm: '5px',
  md: '10px',
  lg: '15px',
  circle: '50%',
}

export const constants = {
  globalHeaderHeight: '48px',
  mobileNavHalfHeight: '45px',
  globalBufferXS: '8px',
  globalBufferSmall: '16px',
  globalBufferMedium: '24px',
  globalBufferLarge: '36px',
  globalBufferXL: '44px',
  desktopContentWidth: '1080px',
  topLevelZIndex: 1305,
}

export const screenSize = {
  large: (styles: {}) => css`
    @media (min-width: 769px) {
      ${styles}
    }
  `,
  mobile: (styles: {}) => css`
    @media (max-width: 768px) {
      ${styles}
    }
  `,
}
