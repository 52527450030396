import styled from '@emotion/styled'
import { Drawer, Stack, Toolbar, Typography, css } from '@mui/material'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { constants, screenSize } from '../tokens'

export default function VerticalDrawer({
  isOpen = false,
  setIsClosed,
  anchor = 'left',
  title = '',
  subtitle = '',
  footer,
  toolbarSx,
  children,
}: {
  isOpen?: boolean
  setIsClosed: any
  anchor?: 'left' | 'top' | 'right' | undefined
  title?: string
  subtitle?: string
  toolbarSx?: any
  footer?: JSX.Element | JSX.Element[] | string
  children?: JSX.Element | JSX.Element[] | string | React.ReactNode
}) {
  const router = useRouter()

  useEffect(() => {
    if (typeof setIsClosed === 'function') {
      setIsClosed()
    }
  }, [router.pathname])

  return (
    <Drawer anchor={anchor} open={isOpen} onClose={() => setIsClosed()} sx={{ zIndex: 1300 }}>
      <StyledToolbar
        sx={{
          px: [1],
          ...toolbarSx,
        }}
      >
        <Wrapper>
          <div style={{ marginBottom: !!subtitle || !!title ? constants.globalBufferSmall : 0 }}>
            {!!subtitle && <Typography variant="body1">{subtitle}</Typography>}
            {!!title && <Typography variant="h5">{title}</Typography>}
          </div>
          <ContentSection>{children}</ContentSection>
        </Wrapper>
        <FooterContainer>{footer}</FooterContainer>
      </StyledToolbar>
    </Drawer>
  )
}

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: ${constants.globalBufferSmall};
  padding-bottom: ${constants.globalBufferSmall};
  width: 90vw;
  max-width: 400px;

  ${screenSize.large(css`
    width: 350px;
  `)}
`

const Wrapper = styled(Stack)`
  width: 100%;
  color: #333;
  margin-top: 60px;
`

const ContentSection = styled.div`
  // padding: ${constants.globalBufferSmall};
  width: 100%;
`

const FooterContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding-bottom: ${constants.globalBufferSmall};
  align-items: flex-end;
  justify-content: flex-end;
`
