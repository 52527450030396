import { Collapse, FormHelperText } from '@mui/material'

export default function FormFieldError({
  error,
  message,
}: {
  error: boolean
  message?: string | undefined
}) {
  return (
    <Collapse in={Boolean(error)}>
      <FormHelperText error>{error ? message : ' '}</FormHelperText>
    </Collapse>
  )
}
